.Link {
  color: var(--accent);
  text-decoration: none;
  border: 0;
  background: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  font-size: inherit;
  display: inline;
}

.Link .Icon {
  margin-left: 4px;
  display: inline-block;
  vertical-align: middle;
}
