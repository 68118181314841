.ModalPageHeader__in,
.ModalPageHeader__left,
.ModalPageHeader__right {
  box-sizing: border-box;
}

.ModalPageHeader__in {
  display: flex;
  align-items: center;
  white-space: nowrap;
  color: var(--header_text_alternate);
  position: relative;
  z-index: 2;
}

.ModalPage--android .ModalPageHeader__in {
  height: var(--modalheader_height_android);
  padding: 0 4px;
}

.ModalPage--ios .ModalPageHeader__in {
  height: var(--modalheader_height_ios);
  padding: 0 4px;
}

.ModalPageHeader__separator {
  margin-top: -1px;
}

.ModalPageHeader__left,
.ModalPageHeader__right {
  flex: 0 0;
  display: flex;
  align-items: center;
  color: var(--header_tint_alternate);
}

.ModalPageHeader__content {
  display: flex;
  align-items: center;
  overflow: hidden;
}

.ModalPageHeader__content-in {
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 800;
}

.ModalPageHeader__right {
  justify-content: flex-end;
  text-align: right;
}

.ModalPageHeader .PanelHeaderButton .Icon--dismiss_24 {
  color: var(--placeholder_icon_foreground_primary);
}

.ModalPageHeader .PanelHeaderButton .Icon--cancel_24 {
  color: var(--icon_secondary);
}

/**
 * iOS
 */
.ModalPageHeader--ios .ModalPageHeader__in {
  justify-content: space-between;
}

.ModalPageHeader--ios .ModalPageHeader__left {
  flex-grow: 1;
  flex-basis: 0;
  padding-right: 12px;
  font-size: 17px;
}

.ModalPageHeader--ios .ModalPageHeader__left .PanelHeaderButton .Icon--24 {
  padding-left: 12px;
  padding-right: 12px;
}

.ModalPageHeader--ios .ModalPageHeader__content {
  position: relative;
  z-index: 1;
  font-family: var(--font-tt);
  flex: 2 1;
  font-size: 21px;
  justify-content: center;
}

@media (min-width: 320px) {
  .ModalPageHeader--ios .ModalPageHeader__content {
    flex-grow: 3;
  }
}

.ModalPageHeader--ios .ModalPageHeader__right {
  flex-grow: 1;
  flex-basis: 0;
  padding-left: 12px;
  font-size: 17px;
}

.ModalPageHeader--ios .ModalPageHeader__right .PanelHeaderButton:not(:first-child) {
  padding-left: 12px;
}

.ModalPageHeader--ios .ModalPageHeader__right .PanelHeaderButton .Icon--24 {
  padding-left: 12px;
  padding-right: 12px;
}

.ModalPageHeader--ios.ModalPageHeader--sizeX-regular .ModalPageHeader__in {
  padding: 0 8px;
}

.ModalPageHeader--ios.ModalPageHeader--sizeX-regular .ModalPageHeader__left:not(:empty) {
  padding-left: 0;
}

.ModalPageHeader--ios.ModalPageHeader--sizeX-regular .ModalPageHeader__right:not(:empty) {
  padding-right: 0;
}

/**
 * Android
 */
.ModalPageHeader--android .ModalPageHeader__left,
.ModalPageHeader--vkcom .ModalPageHeader__left {
  padding-right: 6px;
}

.ModalPageHeader--android .ModalPageHeader__left:empty {
  padding-right: 12px;
}

.ModalPageHeader--android .ModalPageHeader__left:not(:empty) {
  padding-left: 4px;
}

.ModalPageHeader--android .ModalPageHeader__dismiss,
.ModalPageHeader--vkcom .ModalPageHeader__dismiss {
  color: var(--icon_secondary);
}

.ModalPageHeader--android .ModalPageHeader__content {
  max-width: 100%;
  flex-grow: 2;
}

.ModalPageHeader--android .ModalPageHeader__content-in {
  font-size: 20px;
  font-family: var(--font-tt);
}

.ModalPageHeader--android .ModalPageHeader__right,
.ModalPageHeader--vkcom .ModalPageHeader__right {
  padding-left: 6px;
}

.ModalPageHeader--android .ModalPageHeader__right:not(:empty) {
  padding-right: 4px;
}

.ModalPageHeader--android.ModalPageHeader--sizeX-regular .ModalPageHeader__in {
  padding: 0 8px;
}

.ModalPageHeader--android .ModalPageHeader__left:empty {
  padding-right: 14px;
}

.ModalPageHeader--android.ModalPageHeader--sizeX-regular .ModalPageHeader__left:not(:empty) {
  padding-left: 0;
}

.ModalPageHeader--android .ModalPageHeader__right:empty {
  padding-left: 14px;
}

.ModalPageHeader--android.ModalPageHeader--sizeX-regular .ModalPageHeader__right:not(:empty) {
  padding-right: 0;
}

/**
 * VKCOM
 */

.ModalPageHeader--vkcom .ModalPageHeader__in {
  height: var(--panelheader_height_vkcom);
  justify-content: space-between;
  padding: 0 2px;
}

.ModalPageHeader--vkcom .ModalPageHeader__left,
.ModalPageHeader--vkcom .ModalPageHeader__right {
  flex-grow: 1;
  flex-basis: 0;
}

.ModalPageHeader--vkcom .ModalPageHeader__left {
  padding-right: 1px;
}

.ModalPageHeader--vkcom .ModalPageHeader__right {
  padding-left: 1px;
}

.ModalPageHeader--vkcom .ModalPageHeader__left:empty {
  padding-right: 10px;
}

.ModalPageHeader--vkcom .ModalPageHeader__right:empty {
  padding-left: 10px;
}

.ModalPageHeader--vkcom .ModalPageHeader__content {
  max-width: 100%;
}

.ModalPageHeader--vkcom .ModalPageHeader__content-in {
  display: flex;
  align-items: center;
  font-size: 15px;
  line-height: 20px;
  font-weight: 500;
}

.ModalPageHeader--vkcom .ModalPageHeader__content-in > span {
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
}
