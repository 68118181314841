.Cell {
  position: relative;
  margin: 0;
  padding: 0;
}

.List--dragging .Cell:not(.Cell--dragging) {
  transition: transform .3s ease;
}

.List--dragging .Cell--dragging,
.Cell--dragging {
  background-color: var(--background_content);
  box-shadow: 0 0 5px rgba(0, 0, 0, .25);
  z-index: 3;
}

.Cell__dragger {
  position: relative;
}

.Cell .Cell__dragger .Icon {
  color: var(--icon_secondary);
}

.Cell__checkbox {
  display: none;
}

.Cell--selectable .Cell__marker {
  position: relative;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  margin-right: 16px;
  padding: 0;
  color: var(--icon_tertiary);
}

.Cell--selectable .Cell__marker-in--checked {
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  color: var(--accent);
}

.Cell--selectable.Cell--disabled {
  opacity: .6;
}

.Cell--selectable .Cell__checkbox:checked ~ .Cell__marker .Cell__marker-in--checked {
  visibility: initial;
}

.Cell--removable .SimpleCell {
  max-width: 100%;
  flex-grow: 1;
  min-width: 0;
  padding-right: 2px;
  flex-shrink: initial;
  overflow: hidden;
}

/**
 * iOS
 */
.Cell--ios .Cell__in {
  transition: transform .6s var(--ios-easing);
}

.Cell--ios.Cell--selectable .Cell__marker {
  margin-right: 12px;
}

.Cell--ios.Cell--removable .SimpleCell {
  padding-left: 0;
  padding-right: 12px;
}

/**
 * ANDROID & VKCOM
 */
.Cell--android .Cell__dragger,
.Cell--vkcom .Cell__dragger {
  padding-right: 16px;
}
