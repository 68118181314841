.Counter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Counter__in {
  display: block;
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
  box-sizing: border-box;
}

.Counter--primary {
  background: var(--counter_primary_background);
  color: var(--counter_primary_text);
}

.Counter--secondary {
  background: var(--counter_secondary_background);
  color: var(--counter_secondary_text);
}

.Counter--prominent {
  background: var(--counter_prominent_background);
  color: var(--counter_prominent_text);
}

.Counter--s-m {
  height: 24px;
  min-width: 24px;
  border-radius: 12px;
}

.Counter--s-s {
  height: 18px;
  min-width: 18px;
  border-radius: 9px;
}

.Counter--vkcom.Counter--s-m .Counter__in {
  padding-left: 6px;
  padding-right: 6px;
}

/**
 * Button
 */
.Button--lvl-primary .Counter {
  background-color: var(--button_primary_foreground);
  color: var(--button_primary_background);
}

.Button--lvl-secondary .Counter {
  background-color: var(--button_secondary_foreground);
  color: var(--background_content);
}

.Button--lvl-tertiary .Counter {
  background-color: var(--button_tertiary_foreground);
  color: var(--background_content);
}

.Button--lvl-outline .Counter {
  background-color: var(--button_outline_foreground);
  color: var(--background_content);
}

.Button--lvl-commerce .Counter {
  background-color: var(--button_commerce_foreground);
  color: var(--button_commerce_background);
}

.Button--lvl-secondary .Counter--vkcom,
.Button--lvl-tertiary .Counter--vkcom,
.Button--lvl-outline .Counter--vkcom {
  opacity: .72;
}
