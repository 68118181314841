.FormLayoutGroup--horizontal {
  padding: 12px 16px;
  display: flex;
  flex-flow: row nowrap;
}

.FormLayoutGroup--horizontal .FormItem {
  max-width: 100%;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  padding: 0;
  min-width: 0;
}

.FormLayoutGroup--horizontal .FormItem + .FormItem {
  margin-left: 24px;
}

/**
 * iOS
 */

.FormLayoutGroup--ios.FormLayoutGroup--horizontal {
  padding-left: 12px;
  padding-right: 12px;
}
