.Input {
  position: relative;
  line-height: 19px;
  font-size: 16px;
}

.Input--center .Input__el {
  text-align: center;
}

.Input--right .Input__el {
  text-align: right;
}

.Input__el {
  margin: 0;
  width: 100%;
  display: block;
  box-sizing: border-box;
  box-shadow: none;
  border: none;
  appearance: none;
  text-overflow: ellipsis;
  height: 42px;
  line-height: 19px;
  font-size: 16px;
  color: var(--text_primary);
  padding: 11px;
  position: relative;
  z-index: 2;
  background: transparent;
}

.Input--sizeY-compact .Input__el {
  height: 34px;
  padding-top: 9px;
  padding-bottom: 9px;
  line-height: 20px;
  font-size: 15px;
}

.Input__el:disabled {
  opacity: .4;
}

.Input__el::placeholder {
  color: var(--field_text_placeholder);
}

.Input__el:disabled::placeholder {
  color: var(--text_secondary);
}
