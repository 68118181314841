.Removable {
  position: relative;
  min-width: 0;
  width: 100%;
  max-width: 100%;
}

.Removable__content {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.Removable__action {
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  border: none;
}

.Removable--start .Removable__action {
  align-self: flex-start;
}

.FormItem .Removable,
.FormLayoutGroup .Removable {
  padding-left: 16px;
  padding-right: 16px;
  margin-left: -16px;
  margin-right: -16px;
}

.FormItem--withTop .Removable__action,
.FormItem--withTop ~ .Removable__action {
  top: 28px;
}

.FormItem--withTop .Removable--sizeY-compact .Removable__action,
.Removable--sizeY-compact .FormItem--withTop ~ .Removable__action {
  top: 26px;
}

.Cell .Removable {
  padding-right: 6px;
  box-sizing: border-box;
}

/**
 * iOS
 */
.Removable--ios {
  overflow: hidden;
}

.Removable--ios .Removable__content {
  transition: transform .6s var(--ios-easing);
}

.Removable--ios .Removable__action--remove {
  position: absolute;
  left: 100%;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 10px;
  font-size: 15px;
  line-height: 45px;
  color: var(--white);
  background-color: var(--destructive);
  transition: transform .6s var(--ios-easing);
}

.Removable--ios .Removable__action--indicator {
  display: block;
  width: 44px;
  height: 44px;
  margin-left: -10px;
  margin-right: 2px;
  padding: 11px;
  background: none;
}

.Removable--ios.Removable--sizeY-compact .Removable__action--indicator {
  height: 36px;
  padding-top: 7px;
  padding-bottom: 7px;
}

.Removable--ios .Removable__action--indicator .Removable__action-in {
  position: relative;
  display: block;
  width: 22px;
  height: 22px;
  background-color: var(--destructive);
  border-radius: 50%;
  border: none;
}

.Removable--ios .Removable__action--indicator .Removable__action-in::before {
  content: "";
  position: absolute;
  top: 10px;
  left: 5px;
  display: block;
  width: 12px;
  height: 2px;
  background-color: var(--white);
}

.Cell .Removable--ios {
  padding-right: 0;
}

.Cell .Removable--ios .Removable__action--indicator {
  margin-left: 2px;
  margin-right: 0;
}

.FormItem .Removable--ios,
.FormLayoutGroup .Removable--ios {
  padding-left: 12px;
  padding-right: 12px;
  margin-left: -12px;
  margin-right: -12px;
}

.FormLayoutGroup .Removable--ios .Removable__content {
  flex-wrap: wrap;
}

.FormLayoutGroup .Removable--ios .FormItem--withTop ~ .Removable__offset {
  order: -1;
  display: block;
  width: 100%;
  height: 28px;
}

.FormLayoutGroup .Removable--ios.Removable--sizeY-compact .FormItem--withTop ~ .Removable__offset {
  height: 26px;
}

/**
 * ANDROID & VKCOM
 */
.FormItem .Removable--android .Removable__action,
.FormItem .Removable--vkcom .Removable__action,
.FormLayoutGroup .Removable--android .Removable__action,
.FormLayoutGroup .Removable--vkcom .Removable__action {
   margin-top: -2px;
   margin-right: -16px;
   margin-bottom: -2px;
}

.FormItem--sizeY-compact .Removable--android .Removable__action,
.FormItem--sizeY-compact .Removable--vkcom .Removable__action,
.FormLayoutGroup--sizeY-compact .Removable--android .Removable__action,
.FormLayoutGroup--sizeY-compact .Removable--vkcom .Removable__action {
   margin-left: 2px;
   margin-top: -4px;
   margin-right: -14px;
   margin-bottom: -4px;
}
