.Panel {
  position: relative;
  width: 100%;
  height: 100%;
}

.Panel::after {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  content: '';
}

.Panel--centered .PanelHeader {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}

.Panel__in {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  min-height: 100%;
  z-index: 2;
  display: flex;
  flex-direction: column;
}

.Panel__centered {
  display: flex;
  flex: 1 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.Panel__centered .PanelHeader--ios.PanelHeader--vis + * {
  margin-top: var(--panelheader_height_ios);
}

.Panel__centered .PanelHeader--android.PanelHeader--vis + *,
.Panel__centered .PanelHeader--vkcom.PanelHeader--vis + * {
  margin-top: var(--panelheader_height_android);
}

.Panel--sizeX-compact .Panel__centered .PanelHeader--ios.PanelHeader--sep + * {
  margin-top: calc(var(--panelheader_height_ios) + 5px);
}

.Panel--sizeX-compact .Panel__centered .PanelHeader--android.PanelHeader--sep + * {
  margin-top: calc(var(--panelheader_height_android) + 5px);
}

.Panel--sizeX-compact .Panel__centered .PanelHeader--vkcom.PanelHeader--sep + * {
  margin-top: calc(var(--panelheader_height_vkcom) + 5px);
}

.Panel--sizeX-regular .Panel__centered .PanelHeader--ios.PanelHeader--sep + * {
  margin-top: calc(var(--panelheader_height_ios) + 16px);
}

.Panel--sizeX-regular .Panel__centered .PanelHeader--android.PanelHeader--sep + * {
  margin-top: calc(var(--panelheader_height_android) + 16px);
}

.Panel--sizeX-regular .Panel__centered .PanelHeader--vkcom.PanelHeader--sep + * {
  margin-top: calc(var(--panelheader_height_vkcom) + 16px);
}

.Epic .Panel__in {
  padding-bottom: var(--tabbar_height);
  padding-bottom: calc(var(--safe-area-inset-bottom) + var(--tabbar_height));
}

.Panel__in-before {
  height: 1px;
  margin-bottom: -1px;
}

.Panel__separator {
  position: relative;
  z-index: 3;
}

.Panel__in-after {
  height: 1px;
  margin-top: -1px;
}

.Panel .Panel__in,
.Panel::after {
  background-color: var(--background_content);
}

.Panel.Panel--regular .Panel__in,
.Panel.Panel--regular::after {
  background-color: transparent;
}
