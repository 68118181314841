.UsersStack {
  display: flex;
  align-items: center;
  color: var(--text_secondary);
}

.UsersStack__photos {
  display: flex;
}

.UsersStack__photo {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  background-color: var(--placeholder_icon_background);
}

.UsersStack__photo--others {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: var(--content_placeholder_icon);
}

.UsersStack--size-xs .UsersStack__photo {
  width: 16px;
  height: 16px;
}

.UsersStack--size-s .UsersStack__photo {
  width: 24px;
  height: 24px;
}

.UsersStack--size-m .UsersStack__photo {
  width: 32px;
  height: 32px;
}

.UsersStack__photo + .UsersStack__photo {
  margin-left: 4px;
}

.UsersStack:not(.UsersStack--simple) .UsersStack__photo + .UsersStack__photo {
  margin-left: -2px;
}

.UsersStack--size-xs:not(.UsersStack--simple) .UsersStack__photo + .UsersStack__photo {
  margin-left: -1px;
  clip-path: url(#users_stack_mask_16_left);
}

.UsersStack--size-s:not(.UsersStack--simple) .UsersStack__photo + .UsersStack__photo {
  clip-path: url(#users_stack_mask_24_left);
}

.UsersStack--size-m:not(.UsersStack--simple) .UsersStack__photo + .UsersStack__photo {
  clip-path: url(#users_stack_mask_32_left);
}

.UsersStack--size-m.UsersStack--others:not(.UsersStack--simple) .UsersStack__photo {
  clip-path: url(#users_stack_mask_32_right);
}

.UsersStack--size-m.UsersStack--others:not(.UsersStack--simple) .UsersStack__photo:last-child {
  clip-path: none;
}

.UsersStack__text {
  min-width: 0;
  flex: 1;
  margin: 0 0 0 8px;
}

/* Vertical layout */

.UsersStack--l-vertical {
  flex-direction: column;
  justify-content: center;
}

.UsersStack--l-vertical .UsersStack__text {
  margin: 8px 0 0;
  text-align: center;
}

/**
 * ModalCardBase
 */

.ModalCardBase .UsersStack {
  margin-top: 20px;
}
