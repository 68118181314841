.TabbarItem {
  flex-shrink: 0;
  max-width: 100%;
  flex-grow: 1;
  flex-basis: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--tabbar_inactive_icon);
  text-decoration: none;
}

.Tabbar--l-vertical .TabbarItem.TabbarItem--text {
  position: relative;
  top: 2px;
}

.TabbarItem--selected {
  color: var(--tabbar_active_icon);
}

.TabbarItem__in {
  align-self: center;
  display: flex;
  max-width: 100%;
  padding: 0 2px;
  box-sizing: border-box;
}

.Tabbar--l-vertical .TabbarItem__in {
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Tabbar--l-horizontal .TabbarItem__in {
  flex-direction: row;
  align-items: center;
}

.TabbarItem__icon {
  position: relative;
}

.TabbarItem__label .Counter {
  position: absolute;
  top: -2px;
  right: -9px;
}

.TabbarItem__label .Badge {
  position: absolute;
  top: -2px;
  right: -4px;
}

.TabbarItem__text {
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Tabbar--l-vertical .TabbarItem__text {
  font-size: 10px;
  line-height: 12px;
  font-weight: 500;
  margin-top: 2px;
}

.Tabbar--l-horizontal .TabbarItem__text {
  font-size: 13px;
  font-weight: 500;
  margin-left: 8px;
}
