.WriteBar {
  background: var(--background_content);
}

.WriteBar__before,
.WriteBar__form,
.WriteBar__after {
  display: flex;
  align-items: flex-end;
}

.WriteBar__form {
  min-height: 52px;
}

.WriteBar__formIn {
  flex: 1;
  min-width: 0;
  display: flex;
  position: relative;
}

.WriteBar__textarea {
  box-sizing: border-box;
  width: 100%;
  flex: 1;
  min-width: 0;
  margin: 0;
  background: transparent;
  border: none;
  resize: none;
  line-height: 20px;
  color: var(--text_primary);
  -webkit-appearance: none;
  max-height: 120px;
}

.WriteBar__textarea::placeholder {
  color: var(--text_placeholder);
}

.WriteBar__textarea::-moz-placeholder {
  opacity: 1;
  color: var(--text_placeholder);
}

.WriteBar__inlineAfter {
  display: flex;
  align-items: flex-end;
}

/**
 * iOS
 */

.WriteBar--ios .WriteBar__before,
.WriteBar--ios .WriteBar__after {
  padding: 0 4px;
}

.WriteBar--ios .WriteBar__formIn {
  box-sizing: border-box;
  background-color: var(--input_background);
  border: 1px solid var(--input_border);
  border-radius: 18px;
  margin: 8px 0;
}

.WriteBar--ios .WriteBar__formIn:first-child {
  margin-left: 12px;
}

.WriteBar--ios .WriteBar__inlineAfter {
  margin-right: -1px;
  margin-top: -9px;
  margin-bottom: -9px;
}

.WriteBar--ios .WriteBar__textarea {
  height: 34px;
  padding: 6px 11px;
  font-size: 17px;
  line-height: 22px;
}

/**
 * Android + vkcom
 */

.WriteBar--android .WriteBar__before,
.WriteBar--vkcom .WriteBar__before {
  padding-left: 4px;
}

.WriteBar--android .WriteBar__after,
.WriteBar--vkcom .WriteBar__after {
  padding-right: 4px;
}

.WriteBar--android .WriteBar__textarea,
.WriteBar--vkcom .WriteBar__textarea {
  height: 52px;
  padding: 16px 12px;
  font-size: 16px;
  line-height: 20px;
}
