.FormItem {
  display: block;
  padding: 12px 16px;
}

.FormItem__removable {
  flex-grow: 1;
  flex-shrink: initial;
  min-width: 0;
  max-width: 100%;
  overflow: hidden;
}

.FormItem__top {
  padding-top: 2px;
  padding-bottom: 8px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--text_secondary);
}

.FormItem--sizeY-compact .FormItem__top {
  padding-bottom: 6px;
}

.FormItem__bottom {
  color: var(--text_secondary);
  padding-top: 8px;
}

.FormItem--sizeY-compact .FormItem__bottom {
  padding-top: 6px;
}

.FormItem--error .FormItem__bottom {
  color: var(--field_error_border);
}

.FormItem--valid .FormItem__bottom,
.FormItem--verified .FormItem__bottom {
  color: var(--field_valid_border);
}

.FormItem .Cell,
.FormItem .SimpleCell,
.FormItem .RichCell,
.FormItem .CellButton,
.FormItem .Radio,
.FormItem .Checkbox {
  width: 100%;
  box-sizing: content-box;
  margin-left: -16px;
  margin-right: -16px;
}

/**
 * iOS
 */

.FormItem--ios {
  padding-left: 12px;
  padding-right: 12px;
}

.FormItem--ios .Cell,
.FormItem--ios .SimpleCell,
.FormItem--ios .RichCell,
.FormItem--ios .CellButton,
.FormItem--ios .Radio,
.FormItem--ios .Checkbox {
  margin-left: -12px;
  margin-right: -12px;
}

.FormLayoutGroup .Removable .FormItem--ios.FormItem--withTop {
  margin-top: -28px;
}

.FormLayoutGroup .Removable .FormItem--ios.FormItem--sizeY-compact.FormItem--withTop {
  margin-top: -26px;
}
