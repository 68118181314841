.Gradient--md-tint.Gradient--to-top {
  background: linear-gradient(to top, #f5f5f5, rgba(245, 245, 245, .98), rgba(245, 245, 245, .94), rgba(245, 245, 245, .88), rgba(245, 245, 245, .8), rgba(245, 245, 245, .71), rgba(245, 245, 245, .61), rgba(245, 245, 245, .5), rgba(245, 245, 245, .39), rgba(245, 245, 245, .29), rgba(245, 245, 245, .2), rgba(245, 245, 245, .12), rgba(245, 245, 245, .06), rgba(245, 245, 245, .02), rgba(245, 245, 245, 0));
}

.Gradient--md-tint.Gradient--to-bottom {
  background: linear-gradient(to bottom, #f5f5f5, rgba(245, 245, 245, .98), rgba(245, 245, 245, .94), rgba(245, 245, 245, .88), rgba(245, 245, 245, .8), rgba(245, 245, 245, .71), rgba(245, 245, 245, .61), rgba(245, 245, 245, .5), rgba(245, 245, 245, .39), rgba(245, 245, 245, .29), rgba(245, 245, 245, .2), rgba(245, 245, 245, .12), rgba(245, 245, 245, .06), rgba(245, 245, 245, .02), rgba(245, 245, 245, 0));
}

body[scheme='space_gray'] .Gradient--md-tint.Gradient--to-top {
  background: linear-gradient(to top, #232324, rgba(35, 35, 36, .98), rgba(35, 35, 36, .94), rgba(35, 35, 36, .88), rgba(35, 35, 36, .8), rgba(35, 35, 36, .71), rgba(35, 35, 36, .61), rgba(35, 35, 36, .5), rgba(35, 35, 36, .39), rgba(35, 35, 36, .29), rgba(35, 35, 36, .2), rgba(35, 35, 36, .12), rgba(35, 35, 36, .06), rgba(35, 35, 36, .02), rgba(35, 35, 36, 0));
}

body[scheme='space_gray'] .Gradient--md-tint.Gradient--to-bottom {
  background: linear-gradient(to bottom, #232324, rgba(35, 35, 36, .98), rgba(35, 35, 36, .94), rgba(35, 35, 36, .88), rgba(35, 35, 36, .8), rgba(35, 35, 36, .71), rgba(35, 35, 36, .61), rgba(35, 35, 36, .5), rgba(35, 35, 36, .39), rgba(35, 35, 36, .29), rgba(35, 35, 36, .2), rgba(35, 35, 36, .12), rgba(35, 35, 36, .06), rgba(35, 35, 36, .02), rgba(35, 35, 36, 0));
}
