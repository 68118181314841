.FixedLayout {
  position: fixed;
  box-sizing: border-box;
  left: auto;
  z-index: 3;
  padding-left: var(--safe-area-inset-left);
  padding-right: var(--safe-area-inset-right);
}

.FixedLayout--filled {
  background: var(--background_content);
}

.FixedLayout--top {
  width: 100%;
  top: 0;
}

.FixedLayout--bottom {
  width: 100%;
  bottom: 0;
  padding-bottom: var(--safe-area-inset-bottom);
}

.Epic .FixedLayout--bottom {
  padding-bottom: calc(var(--tabbar_height) + var(--safe-area-inset-bottom));
}
