.MiniInfoCell {
  display: flex;
  padding: 6px 12px;
}

.MiniInfoCell--lvl-primary {
  color: var(--text_primary);
}

.MiniInfoCell--lvl-secondary {
  color: var(--text_subhead);
}

.MiniInfoCell__icon {
  color: var(--icon_outline_secondary);
}

.MiniInfoCell__content {
  flex: 1;
  min-width: 0;
  margin-left: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.MiniInfoCell--wr-short .MiniInfoCell__content {
  white-space: initial;
  display: -webkit-box;
  max-height: 60px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.MiniInfoCell--wr-full .MiniInfoCell__content {
  overflow: inherit;
  white-space: initial;
}

.MiniInfoCell__after {
  margin-left: 12px;
  margin-top: -2px;
  margin-bottom: -2px;
}

.MiniInfoCell--md-add,
.MiniInfoCell--md-add .MiniInfoCell__icon {
  color: var(--accent);
}

.MiniInfoCell--md-more,
.MiniInfoCell--md-more .MiniInfoCell__icon {
  color: var(--link_alternate);
}

.MiniInfoCell--md-more {
  padding-top: 10px;
  padding-bottom: 10px;
}

/**
 * ANDROID
 */
.MiniInfoCell--android {
  padding-left: 16px;
  padding-right: 16px;
}
