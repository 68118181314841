.Snackbar {
  user-select: none;
  z-index: 101;
  position: fixed;
  bottom: 0;
  left: auto;
  width: 100%;
  padding-left: var(--safe-area-inset-left);
  padding-right: var(--safe-area-inset-right);
  padding-bottom: var(--safe-area-inset-bottom);
}

.Epic .Snackbar {
  padding-bottom: calc(var(--tabbar_height) + var(--safe-area-inset-bottom));
}

.Snackbar__in {
  padding: 8px;
}

.Snackbar--closing .Snackbar__in {
  transform: translate3d(0, 140%, 0);
}

.Snackbar__body {
  box-sizing: border-box;
  padding: 12px 16px;
  min-height: 56px;
  display: flex;
  align-items: center;
  background: var(--modal_card_background);
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, .24), 0 0 2px 0 rgba(0, 0, 0, .08);
}

.Snackbar__before {
  margin-left: -4px;
  margin-right: 12px;
}

.Snackbar__after {
  margin-right: -4px;
  margin-left: 12px;
}

.Snackbar__content {
  flex: 1;
  display: flex;
  align-items: center;
}

.Snackbar--l-vertical .Snackbar__content {
  flex-direction: column;
  align-items: flex-start;
}

.Snackbar__content-text {
  flex: 1;
}

.Snackbar__content-text {
  color: var(--text_primary);
}

.Snackbar--l-horizontal .Snackbar__action {
  margin-right: -16px;
  position: relative;
  top: 2px;
}

.Snackbar--l-vertical .Snackbar__action {
  margin-top: 2px;
  margin-bottom: -6px;
  margin-left: -16px;
}

.ModalRoot .Snackbar {
  padding-bottom: var(--safe-area-inset-bottom);
}

/* iOS */
.Snackbar--ios .Snackbar__in,
.Snackbar--ios .Snackbar__body {
  transition: transform 400ms var(--ios-easing);
}

.Snackbar--ios .Snackbar__in {
  animation: vkui-animation-snackbar-intro-vertical 340ms var(--ios-easing);
}

.Snackbar--ios .Snackbar__body {
  border-radius: 10px;
}

/* Android */
.Snackbar--android .Snackbar__in,
.Snackbar--android .Snackbar__body,
.Snackbar--vkcom .Snackbar__in,
.Snackbar--vkcom .Snackbar__body {
  transition: transform 320ms var(--android-easing);
}

.Snackbar--android .Snackbar__in,
.Snackbar--vkcom .Snackbar__in {
  animation: vkui-animation-snackbar-intro-vertical 340ms var(--android-easing);
}

.Snackbar--android .Snackbar__body,
.Snackbar--vkcom .Snackbar__body {
  border-radius: 8px;
}

/**
 * Desktop
 */

.Snackbar--desktop {
  max-width: 328px;
  left: 0;
  bottom: 0;
}

.Snackbar--desktop .Snackbar__in {
  padding: 12px;
  animation-name: vkui-animation-snackbar-intro-horizontal;
}

.Snackbar--desktop.Snackbar--closing .Snackbar__in {
  transform: translate3d(-140%, 0, 0);
}

/**
 * Touch-interaction
 */
.Snackbar--touched .Snackbar__body {
  transition: none;
}

/**
 * Animations
 */
@keyframes vkui-animation-snackbar-intro-vertical {
  from {
    transform: translate3d(0, 140%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes vkui-animation-snackbar-intro-horizontal {
  from {
    transform: translate3d(-140%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}
