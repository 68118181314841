.ActionSheet {
  position: relative;
  overflow: hidden;
}

.ActionSheet__header {
  color: var(--text_secondary);
}

/**
 * iOS
 */
.ActionSheet--ios {
  width: 100%;
  animation: vkui-animation-actionsheet-intro .3s var(--ios-easing);
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.ActionSheet--ios.ActionSheet--closing {
  transform: translateY(100%);
  transition: transform .3s var(--ios-easing);
}

.ActionSheet--ios .ActionSheet__header {
  padding: 15px 10px;
  position: relative;
  overflow: hidden;
  border-radius: 14px 14px 0 0;
  text-align: center;
}

.ActionSheet--ios .ActionSheet__title {
  position: relative;
  z-index: 2;
}

.ActionSheet--ios .ActionSheet__title + .ActionSheet__text {
  margin-top: 9px;
}

.ActionSheet--ios .ActionSheet__text {
  position: relative;
  z-index: 2;
}

.ActionSheet--ios .ActionSheet__header::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var(--modal_card_background);
  z-index: 1;
  content: '';
}

/**
 * Android
 */
.ActionSheet--android,
.ActionSheet--vkcom {
  width: calc(100% - 20px);
  margin: 10px;
  animation: vkui-animation-actionsheet-intro .2s var(--android-easing);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border-radius: 12px;
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, .16), 0 0 4px 0 rgba(0, 0, 0, .08);
  padding: 8px 0;
  background: var(--modal_card_background);
}

.ActionSheet--android.ActionSheet--closing {
  transform: translateY(calc(100% + 20px));
  transition: transform .2s var(--android-easing);
}

.ActionSheet--android .ActionSheet__header,
.ActionSheet--vkcom .ActionSheet__header {
  padding: 16px 20px;
}

.ActionSheet--android .ActionSheet__title + .ActionSheet__text,
.ActionSheet--vkcom .ActionSheet__title + .ActionSheet__text {
  margin-top: 8px;
}

/**
 * Desktop version
 */

.ActionSheet--desktop {
  width: auto;
  height: auto;
  background: var(--modal_card_background);
  animation: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 110;
  padding: 0;
  margin: 0;
}

.ActionSheet--desktop.ActionSheet--sizeY-compact {
  padding-top: 8px;
  padding-bottom: 8px;
}

.ActionSheet--desktop.ActionSheet--ios {
  border-radius: 14px;
  box-shadow: 0 0 96px rgba(0, 0, 0, .16), 0 0 2px rgba(0, 0, 0, .12);
  padding: 0;
}

.ActionSheet--desktop.ActionSheet--android {
  min-width: 112px;
  border-radius: 12px;
  padding: 8px 0;
  box-shadow: 0 8px 8px rgba(0, 0, 0, .16), 0 0 4px rgba(0, 0, 0, .08);
}

.ActionSheet--desktop.ActionSheet--vkcom {
  border-radius: 12px;
  box-shadow: 0 0 2px rgba(0, 0, 0, .08), 0 8px 24px rgba(0, 0, 0, .08);
}

.ActionSheet--desktop.ActionSheet--vkcom .ActionSheet__header {
  padding: 14px 20px;
}

/**
* Animations
*/
@keyframes vkui-animation-actionsheet-intro {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
}
