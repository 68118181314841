.PromoBanner {
  width: 100%;
  box-sizing: border-box;
  outline: 1px solid var(--separator_alpha);
  color: var(--text_primary);
  background: var(--background_content);
  padding-bottom: 10px;
  position: relative;
}

.PromoBanner__head {
  display: flex;
  padding-top: 14px;
  padding-bottom: 10px;
  color: var(--text_secondary);
}

.PromoBanner__age {
  margin-left: 6px;
}

.PromoBanner__close {
  position: absolute;
  top: 0;
  right: 0;
  width: 48px;
  height: 48px;
  color: var(--placeholder_icon_foreground_primary);
  display: flex;
  justify-content: center;
  align-items: center;
}

.PromoBanner__close:active {
  opacity: .7;
}

.PromoBanner__pixels {
  display: none;
}

/* iOS */

.PromoBanner--ios .PromoBanner__head {
  padding-left: 12px;
  padding-right: 12px;
}

/* Android */

.PromoBanner--android .PromoBanner__head,
.PromoBanner--vkcom .PromoBanner__head {
  padding-left: 16px;
  padding-right: 16px;
}
